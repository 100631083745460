import { graphql } from "gatsby";
import { useIntl } from "../hooks/useIntl";
import { camelCase, mapKeys, snakeCase } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import Page from "../components/Page";
import {
  Hero,
  RecipeLandingHeroProps,
  RecipesSection,
  RecipesSectionProps,
  ShareYourRecipe,
  ShareYourRecipeProps,
} from "../components/RecipeLanding";
import { RecipeSectionColors } from "../components/RecipeLanding/RecipesSection";
import { getAssetURL } from "../helpers/assets";
import { getTranslations } from "../helpers/translations";
import { mapRecipeCard } from "../helpers/recipe";
import SEO from "../components/SEO";
import { parseSEO } from "../helpers/seo";
import { isPublished } from "../helpers/directus";
import { useStaticLabels } from "../hooks/useStaticLabels";
import { ROUTES } from "../helpers/routes";

const Basic = ({ data }: { data: any }) => {
  const intl = useIntl();
  const labels = useStaticLabels();
  const [recipesSections, setRecipesSections] = useState<RecipesSectionProps[]>(
    []
  );

  const getRootTranslatedData = useCallback(() => {
    return getTranslations(
      data,
      "directus.recipe_landing_page.translations",
      intl.locale
    );
  }, [data]);

  const getHeroData = useCallback((): RecipeLandingHeroProps => {
    const translatedData = getRootTranslatedData();
    const { recipe = [], recipe_content_status } =
      getTranslations(
        translatedData,
        "eat_what_you_love_recipe.translations",
        intl.locale
      ) ?? {};

    const translatedRecipe = isPublished(recipe_content_status)
      ? getTranslations(recipe, "translations", intl.locale)
      : null;

    const translatedRecipeProduct = getTranslations(
      recipe,
      `product_page.translations`,
      intl.locale
    );

    const translatedProduct = getTranslations(
      translatedRecipeProduct,
      `product.translations`,
      intl.locale
    );

    const heroImage = translatedData?.custom_hero_image?.id ? {
      src: getAssetURL(translatedData?.custom_hero_image),
      alt: translatedData?.custom_hero_image?.description,
    } : {
      src: getAssetURL(translatedRecipe?.image),
      alt: translatedRecipe?.image_alt,
    }

    return {
      title1: translatedData?.eat_what_you_love_title,
      searchPlaceholder: translatedData?.eat_what_you_love_search,
      tagsLabel: translatedData?.eat_what_you_love_tags_title,
      recipeSocialMedia: translatedData?.custom_hero_link_label ?? translatedData?.eat_what_you_love_social_media,
      recipeSocialMediaLink: translatedData?.custom_hero_link ?? `${ROUTES.RECIPES}/${translatedData?.eat_what_you_love_recipe?.recipe_page_slug}`,
      recipeSubtitle:  translatedData?.custom_hero_eyebrow ??translatedProduct?.product_name,
      recipeTitle: translatedData?.custom_hero_headline ?? translatedRecipe?.title,
      image: heroImage,
      allRecipes: labels?.allRecipes,
      tags: isPublished(translatedData?.product_recipes_status)
        ? translatedData?.product_recipes_list
            ?.map(
              ({
                product_recipes_section_id,
              }: {
                product_recipes_section_id: any;
              }) => {
                const tranlatedList = getTranslations(
                  product_recipes_section_id,
                  "translations",
                  intl.locale
                );
                return {
                  status: tranlatedList?.status,
                  label: tranlatedList?.search_keyword ?? "",
                  link: `#${snakeCase(tranlatedList?.search_keyword)}`,
                };
              }
            )
            ?.filter(({ status }: { status: string }) => isPublished(status))
        : [],
    };
  }, [getRootTranslatedData, recipesSections]);

  const getSectionColors = (): RecipeSectionColors => {
    return {
      headerColor: "#ccb983",
      bgColor: "#471d0e",
      itemBgColor: "#7a3817",
      itemTextColor: "white",
      itemLabelColor: "#ccb983",
      itemHoverTextColor: "#471D0E",
      itemHoverBgColor: "#ccb983",
    };
  };

  const getShowUsYourChops = (): ShareYourRecipeProps => {
    const translatedData = getRootTranslatedData();

    return {
      title: translatedData?.show_us_your_chops_title,
      description: translatedData?.show_us_your_chops_description,
      buttonLabel: translatedData?.show_us_your_chops_button_label,
      buttonLink: translatedData?.show_us_your_chops_button_link,
      image1: {
        src: getAssetURL(translatedData?.show_us_your_chops_recipe_image),
        alt: translatedData?.show_us_your_chops_recipe_image_alt,
      },
      image2: {
        src: getAssetURL(translatedData?.show_us_your_chops_social_media_image),
        alt: translatedData?.show_us_your_chops_social_media_image_alt,
      },
      socialMediaUser: translatedData?.show_us_your_chops_social_media_user,
      socialMediaOpinion:
        translatedData?.show_us_your_chops_social_media_opinion,
      socialMediaLink: translatedData?.show_us_your_chops_social_media_link,
    };
  };

  const getFeaturedRecipesSection = (): RecipesSectionProps => {
    const translatedData = getRootTranslatedData();

    return {
      isPublished: isPublished(translatedData?.featured_recipes_status),
      title: translatedData?.featured_recipes_title,
      recipeList: translatedData?.featured_recipes_list
        .map(({ recipe_page_id }: { recipe_page_id: any }) =>
          mapRecipeCard(recipe_page_id, labels, intl.locale)
        )
        ?.filter(({ status }: { status: string }) => isPublished(status)),
    };
  };

  const getRecipesSections = (): RecipesSectionProps[] => {
    const translatedData = getRootTranslatedData();
    const sections = translatedData?.product_recipes_list?.map(
      ({ product_recipes_section_id }: { product_recipes_section_id: any }) =>
        getTranslations(product_recipes_section_id, "translations", intl.locale)
    );

    return sections?.map((section: any) => {
      const formattedSection = mapKeys(section, (_v, k) => camelCase(k));
      return {
        ...formattedSection,
        recipeList: isPublished(translatedData?.product_recipes_status)
          ? section?.recipe_list
              ?.map(({ recipe_page_id }: { recipe_page_id: any }) =>
                mapRecipeCard(recipe_page_id, labels, intl.locale)
              )
              ?.filter(({ status }: { status: string }) => isPublished(status))
          : [],
      };
    });
  };

  useEffect(() => {
    setRecipesSections(getRecipesSections());
  }, [data]);

  useEffect(() => {
    const scrollToHash = () => {
      if (window.location.hash) {
        const targetId = window.location.hash.substring(1);
        const targetElement = document.getElementById(targetId);

        if (targetElement) {
          const paddingTop = parseInt(
            getComputedStyle(targetElement).paddingTop,
            10
          );
          const offset = targetElement.offsetTop - paddingTop * 2;
          window.scrollTo({ top: offset, behavior: "smooth" });
        }
      }
    };

    scrollToHash();
  }, [recipesSections]);

  return (
    <Page
      headerBackgroundColor={"#033305"}
      helmet={<SEO {...parseSEO(getRootTranslatedData(), intl?.locale)} />}
    >
      <Hero {...getHeroData()} />
      <RecipesSection
        {...getFeaturedRecipesSection()}
        {...{
          headerColor: "#012102",
          bgColor: "#ccb983",
          itemBgColor: "#544423",
          itemTextColor: "white",
          itemLabelColor: "#ccb983",
          itemHoverBgColor: "#012102",
          itemHoverTextColor: "#ccb983",
        }}
      />
      {recipesSections
        .filter((section) => section?.recipeList?.length > 0)
        .map((recipeSection) => {
          return (
            <RecipesSection
              key={recipeSection?.searchKeyword}
              {...recipeSection}
              {...getSectionColors()}
            />
          );
        })}
      <ShareYourRecipe {...getShowUsYourChops()} />
    </Page>
  );
};

export default Basic;

export const query = graphql`
  query ($locale: String) {
    directus {
      recipe_landing_page {
        translations(filter: { languages_code: { code: { _eq: $locale } } }) {
          seo_information {
            ...SEO
          }
          languages_code {
            code
            name
          }
          status
          eat_what_you_love_status
          eat_what_you_love_title
          eat_what_you_love_search
          eat_what_you_love_tags_title
          eat_what_you_love_social_media
          custom_hero_image {
            id
            filename_disk
            description
          }
          custom_hero_eyebrow
          custom_hero_headline
          custom_hero_link
          custom_hero_link_label
          eat_what_you_love_recipe {
            recipe_page_slug
            translations(
              filter: { languages_code: { code: { _eq: $locale } } }
            ) {
              languages_code {
                code
                name
              }
              recipe_content_status
              recipe {
                product_page {
                  product_page_slug
                  translations(
                    filter: { languages_code: { code: { _eq: $locale } } }
                  ) {
                    languages_code {
                      code
                      name
                    }
                    variants_status
                    product {
                      translations(
                        filter: { languages_code: { code: { _eq: $locale } } }
                      ) {
                        languages_code {
                          code
                          name
                        }
                        product_name
                      }
                    }
                  }
                }
                translations(
                  filter: { languages_code: { code: { _eq: $locale } } }
                ) {
                  languages_code {
                    code
                    name
                  }
                  title
                  image_alt
                  image {
                    id
                    filename_disk
                  }
                }
              }
            }
          }
          product_recipes_status
          product_recipes_list {
            product_recipes_section_id {
              translations(
                filter: { languages_code: { code: { _eq: $locale } } }
              ) {
                languages_code {
                  code
                  name
                }
                status
                title
                all_recipes_label
                tags {
                  recipe_tag_id {
                    name
                    translations {
                      languages_code {
                        code
                        name
                      }
                      label
                    }
                  }
                }
                search_keyword
                recipe_list {
                  recipe_page_id {
                    recipe_page_slug
                    translations(
                      filter: { languages_code: { code: { _eq: $locale } } }
                    ) {
                      languages_code {
                        code
                        name
                      }
                      status
                      recipe_content_status
                      recipe {
                        product_page {
                          product_page_slug
                          translations(
                            filter: {
                              languages_code: { code: { _eq: $locale } }
                            }
                          ) {
                            languages_code {
                              code
                              name
                            }
                            variants_status
                            product {
                              translations(
                                filter: {
                                  languages_code: { code: { _eq: $locale } }
                                }
                              ) {
                                languages_code {
                                  code
                                  name
                                }
                                product_name
                              }
                            }
                          }
                        }
                        translations(
                          filter: { languages_code: { code: { _eq: $locale } } }
                        ) {
                          languages_code {
                            code
                            name
                          }
                          title
                          serves_value
                          prep_time_value
                          cook_time_value
                          image_alt
                          image {
                            id
                            filename_disk
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          featured_recipes_status
          featured_recipes_title
          featured_recipes_list {
            recipe_page_id {
              recipe_page_slug
              translations(
                filter: { languages_code: { code: { _eq: $locale } } }
              ) {
                languages_code {
                  code
                  name
                }
                status
                recipe_content_status
                recipe {
                  product_page {
                    product_page_slug
                    translations(
                      filter: { languages_code: { code: { _eq: $locale } } }
                    ) {
                      languages_code {
                        code
                        name
                      }
                      variants_status
                      product {
                        translations(
                          filter: { languages_code: { code: { _eq: $locale } } }
                        ) {
                          languages_code {
                            code
                            name
                          }
                          product_name
                        }
                      }
                    }
                  }
                  translations(
                    filter: { languages_code: { code: { _eq: $locale } } }
                  ) {
                    languages_code {
                      code
                      name
                    }
                    title
                    serves_value
                    prep_time_value
                    cook_time_value
                    image_alt
                    image {
                      id
                      filename_disk
                    }
                  }
                }
              }
            }
          }
          show_us_your_chops_status
          show_us_your_chops_title
          show_us_your_chops_description
          show_us_your_chops_button_label
          show_us_your_chops_button_link
          show_us_your_chops_recipe_image_alt
          show_us_your_chops_recipe_image {
            id
            filename_disk
          }
          show_us_your_chops_social_media_user
          show_us_your_chops_social_media_link
          show_us_your_chops_social_media_opinion
          show_us_your_chops_social_media_image {
            id
            filename_disk
          }
          show_us_your_chops_social_media_image_alt
        }
      }
    }
  }
`;
